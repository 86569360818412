<!-- mailchimp list页 -->
<template>
  <div class="mailchimp_box">
    <div class="tit">
      <i class="iconfont iconjiantou-zuo left" @click="goBtn"></i>
    </div>

    <div
      class="content_login"
      v-if="this.$route.params.type == 'authentication'"
    >
      <img class="bar_img" src="@/assets/images/marketing/horizontal-bar.svg" />

      <div class="box">
        <img class="mail_img" src="@/assets/images/marketing/mail_img.svg" />
        <svg aria-hidden="true">
          <use href="#icon-cloudcc-form"></use>
        </svg>
        <div class="add">+</div>
        <div class="describe_box">
          <p>CloudCC Forms + MailChimp</p>
          <!-- 您必须验证您的MailChimp帐号。请点击“验证MailChimp”按钮重定向到Mailchimp已发送市场活动 -->
          <p>
            {{$t('c477')}}
          </p>
          <!-- 验证Mailchimp -->
          <el-button class="btn" size="small" @click="loginFun"
            >{{$t('c478')}}</el-button
          >
        </div>
      </div>
    </div>
    <div class="content" v-if="this.$route.params.type == 'list'">
      <!-- Mailchimp分享中分享CloudCC表单 -->
      <div class="tit_box">{{$t('c479')}}</div>
      <div class="step_box">
        <el-steps :active="active" align-center>
          <el-step :title="$t('label_chatter_addressee')"></el-step>
          <el-step :title="$t('label_emailsync_form_seversetting')"></el-step>
          <el-step :title="$t('c570')"></el-step>
        </el-steps>
        <div class="step_content" v-show="active === 1">
          <div class="form_box">
            <div class="form_tit">
              <!-- 我们将发送到哪个列表？ -->
              <span>{{$t('c480')}}</span>
              <!-- 以其它账号登录 -->
              <span @click="userLogin">{{$t('c481')}}</span>
            </div>
          </div>
          <el-radio class="radio_box" v-model="radio" label="1">{{
            userName
          }}</el-radio>
        </div>
        <div class="step_content" v-show="active == 2" style="height: 410px">
          <div class="form_box">
            <div class="form_tit">
              <span>{{$t('c483')}}</span>
            </div>
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="200px"
            class="demo-ruleForm"
          >
          <!-- 为您的活动命名： -->
            <el-form-item :label="$t('c482')" prop="campaigntittle">
              <el-input v-model="ruleForm.campaigntittle"></el-input>
            </el-form-item>
            <el-form-item :label="$t('c484')" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('c485')" prop="campaignfrom_name">
              <el-input v-model="ruleForm.campaignfrom_name"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('c486')"
              prop="campaignreply_to"
            >
              <el-input v-model="ruleForm.campaignreply_to"></el-input>
            </el-form-item>
            <el-form-item :label="$t('c487')" prop="name1">
              <el-input v-model="ruleForm.name1"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="step_content" v-show="active == 3" style="height: 450px">
          <div class="form_box">
            <div class="form_tit">
              <span></span>
              <!-- 编辑-->
              <span @click="editFun">{{$t('label.department.user.edit')}}</span>
            </div>
          </div>
          <div
            v-show="!isWangEditor"
            style="width: 90%; margin-left: 5%; background: #fff; padding: 20px"
          >
          <div v-html="content"></div>
            <p
              style="
                width: 166px;
                height: 30px;
                line-height: 30px;
                background: #ecf4ff;
                text-align: center;
                border-radius: 2px;
                margin: 0 auto;
                cursor: pointer;
              "
            >
              <span @click="shareurlFun">Fill up the Form!</span>
            </p>
          </div>
          <wangEditor
            v-show="isWangEditor"
            class="wangeditor_box"
            ref="wangeditor"
            style="width: 90%"
            :contents="content"
            @editorContent="editorContent"
          />
        </div>
        <div class="btn_box">
          <el-button
            v-show="active !== 1"
            style="margin-top: 12px"
            size="small"
            @click="back"
            >{{$t('label.emailobject.emaildetail.button.back')}}</el-button
          >
          <el-button
            v-show="active !== 3"
            style="margin-top: 12px"
            size="small"
            :loading="loading"
            @click="active == 2 ? next('ruleForm') : next()"
            >{{$t('label.import.page1.nextstep')}}</el-button
          >
          <el-button
            v-show="active === 3"
            style="margin-top: 12px"
            size="small"
            @click="submit"
            >{{$t('label.forecast.customforecast.button.submit')}}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as marketChannel from "../api.js"; //api
import wangEditor from "@/components/wangEditor/index.vue";

export default {
  components: {
    wangEditor,
  },
  data() {
    return {
      active: 1,
      radio: 2,
      ruleForm: {
        name: "",
        name1: "",
        campaignsubject: "",
        campaignfrom_name: "",
        campaigntittle: "",
        campaignreply_to: "",
        campaignlist_id: "",
      },
      campaignid: "",
      content: "",
      isWangEditor: false,
      userName: "",
      shareurl: this.$route.query.shareurl,
      loading:false,
      rules: {
        // 请填写必填项
        campaigntittle: [
          {
            required: true,
            message: this.$i18n.t("vue_label_lead_required_fields"),
            trigger: "blur",
          },
        ],
        campaignfrom_name: [
          {
            required: true,
            message: this.$i18n.t("vue_label_lead_required_fields"),
            trigger: "blur",
          },
        ],
        campaignreply_to: [
          {
            required: true,
            message: this.$i18n.t("front-kefu-moudle-v1-input-email"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$i18n.t("label.ccchat.person.invite.erroremail"),
            trigger: ["blur", "change"],
          },
        ],
      },
      btnHtml:`<p style='width: 166px;height: 30px;line-height: 30px;background: #ecf4ff;text-align: center;border-radius: 2px;margin: 0 auto;cursor: pointer;margin-bottom: 20px;'><a target="_blank" href=${this.$route.query.shareurl} style='text-decoration: none;
    color: #999999;'>Fill up the Form!</a></p>`
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.type === "list") {
        // 获取list页面
        marketChannel
          .getList()
          .then((res) => {
            if (res.data.length > 0) {
              this.ruleForm.campaignlist_id = res.data[0].id;
              this.userName = res.data[0].name;
              this.radio = "1";
            }
          })
          .catch(() => {});
        this.content =
          "<div><p style='font-size: 22px!important;'><span style='color:#E5851A;margin-right:20px;'>CloudCC</span><span>Forms</span></p><p style='background: #EFEFEF;text-align:center;height: 30px;line-height: 30px;'>Wed love to hear from you!</p><p style='margin: 20px 0;color: #666666;'>Headline</p><p style='color: #999999;font-size:12px;'>A short message about your form and its purpose.</p><p style='margin: 12px 0;color: #999999;font-size:12px;'>Ask for relevant feedback based on your form type.</p></div>"
      }
      //获取登录接口
      marketChannel.getAuthUrl().then((res) => {
        this.loginurl = res.data.loginurl;
      });
    },
    //返回
    goBtn() {
      this.$router.go(-1);
    },
    //登录mailchimp
    loginFun() {
      window.open(this.loginurl);
      this.$router.go(-1);
    },
    //获取编辑富文本框内容
    editorContent(val) {
      this.content = val;
    },
    //存list表单数据
    next(formName) {
      if (this.active == 1) {
        this.ruleForm.campaigntittle = "";
        this.ruleForm.campaignfrom_name = "";
        this.ruleForm.campaignreply_to = "";
        this.active++;
      }
      if (this.active == 2 && formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            let data = {
              campaignsubject: this.ruleForm.campaigntittle,
              campaignfrom_name: this.ruleForm.campaignfrom_name,
              campaigntittle: this.ruleForm.campaigntittle,
              campaignreply_to: this.ruleForm.campaignreply_to,
              campaignlist_id: this.ruleForm.campaignlist_id,
            };

            marketChannel.createCampaign(data).then((res) => {
              this.campaignid = res.data;
              this.loading = false
              this.active++;
            });
          } else {
            return false;
          }
        });
      }
    },
    //提交富文本内容
    submit() {
      let data = {
        contentname: this.userName,
        contenthtml: this.content+this.btnHtml,
        campaignid: this.campaignid,
      };
      marketChannel.createContent(data).then((res) => {
        if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              //   提交成功
              message: this.$i18n.t("label.webtocloudcc.submit.success"),
            });
            this.$router.go(-1)
          } else {
            this.$message({
              showClose: true,
              type: "error",
              //   提交失败
              message: this.$i18n.t("label.umeng.approval.approvalfail"),
            });
          }
      });
    },
    //回退上一步
    back() {
      if (this.active === 3) {
        this.active = 2;
      } else if (this.active === 2) {
        this.active = 1;
      }
    },
    //编辑富文本
    editFun() {
      this.isWangEditor = !this.isWangEditor;
    },
    //分享
    shareurlFun() {
      window.open(this.$route.query.shareurl);
    },
    //登录其他人
    userLogin() {
      window.open(this.loginurl);
    },
  },
};
</script>
<style scoped lang='scss'>
.mailchimp_box {
  width: 100%;
  height: 100%;
  background: #fff;
  .tit {
    width: 100%;
    height: 46px;
    background: #f8f8f8;
    i {
      padding: 12px 24px;
      line-height: 46px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .content_login {
    width: 100%;
    height: 100%;
    background: #f8f8f8;

    .box {
      width: 900px;
      height: 500px;
      margin: 0 auto;
      position: relative;
      svg {
        position: absolute;
        top: 70px;
        left: 90px;
      }
      .mail_img {
        width: 240px;
        position: absolute;
        top: 230px;
        left: 220px;
      }
      .add {
        position: absolute;
        top: 150px;
        left: 360px;
        width: 40px;
        height: 40px;
        font-size: 80px;
        color: #e0e1e1;
      }
      .describe_box {
        position: absolute;
        top: 120px;
        right: 0;
        p:first-child {
          font-size: 24px;
          color: #333333;
        }
        p:nth-child(2) {
          font-size: 12px;
          color: #999999;
          margin-top: 22px;
          width: 430px;
        }
        .btn {
          margin-top: 30px;
          background: #2d6cfc;
          color: #fff;
        }
      }
    }
  }
  .content {
    .tit_box {
      width: 100%;
      height: 80px;
      line-height: 80px;
      text-align: center;
      background: #fdfdff;
      box-shadow: 0px 2px 12px 0px rgba(213, 213, 213, 0.5);
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
    .step_box {
      width: 800px;
      margin: 0 auto;
      position: relative;
      .el-steps {
        width: 470px;
        margin: 20px auto;
        margin-top: 30px;
      }
      .btn_box {
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
        .el-button {
          color: #ffffff;
          background: #2d6cfc;
        }
      }
      .step_content {
        width: 800px;
        height: 350px;
        background: #f5f5f5;
        box-shadow: 0px 2px 12px 0px rgba(213, 213, 213, 0.5);
        border-radius: 2px;
        ::v-deep .wangeditor_box {
          .text {
            height: 230px !important;
            .w-e-text {
              height: 230px !important;
              div {
                height: auto;
              }
            }
          }
        }
        .form_box {
          .form_tit {
            display: flex;
            justify-content: space-between;
            padding: 24px 40px 20px 40px;
            box-sizing: border-box;
            span:first-child {
              font-size: 20px;
              color: #333333;
            }
            span:nth-child(2) {
              font-size: 12px;
              color: #2d6cfc;
              cursor: pointer;
            }
          }
        }
        .radio_box {
          margin-left: 40px;
        }
        ::v-deep .demo-ruleForm {
          margin: 0 auto;
          width: 650px;
          .el-form-item__label {
            font-size: 12px;
          }
          .el-input__inner {
            width: 300px;
            height: 30px;
            line-height: 30px;
          }
          .el-form-item {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}
</style>
